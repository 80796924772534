<template>
  <div style="background:lightgrey;">
    <v-container>
      <br />
      <v-row justify="space-around">
        <v-col cols="12" sm="12">
          <center>
            <v-btn color="warning" dark class="mb-2" @click="printApplication()">
              <v-icon>mdi-content-save</v-icon>PRINT Application Form
            </v-btn>
            <v-btn color="primary" dark class="mb-2" @click="printReceipt()" style="margin-left:10px;">
              <v-icon>mdi-content-save</v-icon>PRINT Receipt
            </v-btn>
          </center>
        </v-col>
      </v-row>
      <v-card>
          <v-card-text>
            <div id="printonlyapplication">
              <div class="pageHeight" style="width:auto; padding:4px;">
                <div style="position: relative;" class="printHeader">
                     <div style="text-align: center; color: blue;height: 130px;">
                      <div cols="12" sm="12" style="color:blue;">
                          <center>
                            <img style="height: 70px; width: auto;" src="https://vierp-test.s3.ap-south-1.amazonaws.com/logo/vu_logo.svg"/>
                            <div style="font-size:18px;padding-top:8px;">
                              <b>Provisional Admission Form (AY : {{ rec.ay }})</b>
                            </div>
                          </center>
                      </div>
                    </div>
                    <div>
                      <hr style="height:1px; background:black;border-radius: 50px;"/>
                      <hr style="height:1px; background:black;border-radius: 50px;"/>
                      <br />
                    </div>
                    <table dense style="width:100%;">
                        <tr>
                          <td style="border-left:1px solid white !important;border-top:1px solid white !important;border-right:1px solid white !important;border-bottom:1px solid whitesmoke !important;" colspan="3"><b>Application Number : </b>{{provisionaladmission.applicationnumber}}<br /><br /></td>
                          <td style="width:150px !important;border-left:1px solid whitesmoke !important;border-top:1px solid white !important;border-right:1px solid white !important;border-bottom:1px solid whitesmoke !important;" rowspan="4">
                            <div class="photo">
                              <img style="width:118px; height:128px; margin-left:-20px; margin: -20px auto 0 -20px; display: block;" :src="provisionaladmission.photo" alt="Photohgraph" v-if="provisionaladmission.photo">
                              <b v-else>Paste Latest Photohgraph Here</b>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="3"><b>Student Name (As per Last Qualifying Examination Mark Sheet) :</b> {{provisionaladmission.fullname_as_per_previous_marksheet}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;"><b>First Name</b></td>
                          <td style="border:1px solid whitesmoke !important;"><b>Middle Name</b></td>
                          <td style="border:1px solid whitesmoke !important;"><b>Last Name</b></td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;">{{provisionaladmission.firstName}}</td>
                          <td style="border:1px solid whitesmoke !important;">{{provisionaladmission.middleName}}</td>
                          <td style="border:1px solid whitesmoke !important;">{{provisionaladmission.lastName}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>SRN :</b> {{provisionaladmission.srn}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>PRN :</b> {{provisionaladmission.grno}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="4"><b>Faculty :</b> {{ provisionaladmission.stream}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="4"><b>Department :</b> {{ provisionaladmission.department}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="4"><b>Programme :</b> {{ provisionaladmission.program}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Date of Birth :</b> {{provisionaladmission.date_of_birth}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Place of Birth :</b> {{provisionaladmission.birth_place}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Gender :</b> {{provisionaladmission.gender}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Email :</b> {{provisionaladmission.email}}</td>
                        </tr>
                         <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>WhatsApp Mobile No. :</b> {{provisionaladmission.mobileno}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Alternate Mobile No. :</b> {{provisionaladmission.alternate_mobileno}}</td>
                        </tr>
                        <tr><td style="border:1px solid whitesmoke !important;" colspan="4"></td></tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Country :</b> {{provisionaladmission.country}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Domicile State :</b> {{provisionaladmission.domacile}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Religion :</b> {{provisionaladmission.religion}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Category :</b> {{provisionaladmission.category}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Cast :</b> {{provisionaladmission.cast}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Aadhar/Passport :</b> {{provisionaladmission.aadhar_no}}{{provisionaladmission.passport_no}}</td>
                        </tr>
                        <tr><td style="border:1px solid whitesmoke !important;"  colspan="4"></td></tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Last Qualifying Exam :</b> {{provisionaladmission.lastqualifyingexam}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Last Qualifying Exam Status:</b> {{provisionaladmission.lastqualifyingexamstatus}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="4"><b>Last Attended College/Institute Name :</b> {{provisionaladmission.last_attended_college_institute_name}}</td>
                        </tr>
                         <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="4"><b>Last Attended College/Institute Address :</b> {{provisionaladmission.last_attended_college_institute_addres}}</td>
                        </tr>
                        <tr><td style="border:1px solid whitesmoke !important;" colspan="4"></td></tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="4"><b>Communication Address :</b> {{provisionaladmission.communication_address}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>State :</b> {{provisionaladmission.state}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>District :</b> {{provisionaladmission.district}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>Taluka :</b> {{provisionaladmission.taluka}}</td>
                          <td style="border:1px solid whitesmoke !important;" colspan="2"><b>City :</b> {{provisionaladmission.city}}</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="4"><b>Pin :</b> {{provisionaladmission.pin}}</td>
                        </tr>
                        <tr><td style="border:1px solid whitesmoke !important;" colspan="4"></td></tr>
                        <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="4"><b>First Instalment :</b> {{provisionaladmission.admissionfees}}/-</td>
                        </tr>
                        <!-- <tr>
                          <td style="border:1px solid whitesmoke !important;" colspan="4"><b>Total Amount Paid(Provisional Admission) :</b> {{provisionaladmission.totalfees}}/-</td>
                        </tr> -->
                    </table>
                    <br />
                    <center style="width: 80%;margin-left: 70px; word-wrap: break-word;">DECLARATION BY THE STUDENT</center>
                    <br />
                    <div>
                      <div cols="12" sm="12">
                        <span style="font-weight:bold;font-size:14px;">
                          I delcare that the information provided above is true, complete and correct.
                        </span>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div>
                      <div cols="12" sm="6" style="float:left;">
                        <br />
                        <b>Place : </b>
                        <br />
                        <b>Form Submission Date : {{provisionaladmission.formsubmitdate}}</b>
                      </div>
                      <div cols="12" sm="6" style="float:right;margin-right:50px;">
                        <br />
                        <br />
                        <img style="width:100px; height:80px; margin: -90px auto 0 20px; display: block;" :src="provisionaladmission.sign" alt="Signature Photo" v-if="provisionaladmission.sign">
                        <b>Signature of the Student</b>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <b style="font-size:12px;">Print Date : {{provisionaladmission.printdate}}</b>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
          </v-card-text>
      </v-card>
      <br>
      <v-card>
          <v-card-text>
            <div id="printonlyreceipt">
              <div class="pageHeight" style="width:auto; padding:4px;">
                <div style="position: relative;" class="printHeader">
                    <div style="text-align: center; color: blue;height: 130px;">
                      <div cols="12" sm="12" style="color:blue;">
                          <center>
                            <img style="height: 70px; width: auto;" src="https://vierp-test.s3.ap-south-1.amazonaws.com/logo/vu_logo.svg"/>
                            <div style="font-size:18px;padding-top:10px;">
                              <b>Provisional Admission (AY : {{ rec.ay }})</b>
                            </div>
                          </center>
                      </div>
                    </div>
                    <div>
                      <hr style="height:1px; background:black;border-radius: 50px;"/>
                      <hr style="height:1px; background:black;border-radius: 50px;"/>
                      <br />
                    </div>
                    <div>
                      <div cols="12" sm="12">
                        <center style="font-size:18px;">
                          <b>Fees Receipt <span style="font-size:13px;">(First Instalment)</span></b>
                        </center>
                      </div>
                    </div>
                    <br />
                    <table dense style="width:100%;">
                        <tr><td style="border:1px solid whitesmoke !important;"><b>Receipt No. :</b> {{rec.rec_no }}</td><td style="border:1px solid whitesmoke !important;"><b>Receipt Date. :</b> {{ rec.rec_date }}</td></tr>
                        <tr><td style="border:1px solid whitesmoke !important;"><b>PRN :</b> {{ rec.grno }}</td><td style="border:1px solid whitesmoke !important;"><b>SRN :</b> {{ rec.srn }}</td></tr>
                        <tr><td style="border:1px solid whitesmoke !important;" colspan="2"><b>Student Name :</b> {{ rec.full_name }}</td></tr>
                        <tr><td style="border:1px solid whitesmoke !important;" colspan="2"><b>Faculty :</b> {{ rec.stream }}</td></tr>
                        <tr><td style="border:1px solid whitesmoke !important;" colspan="2"><b>Department :</b> {{ rec.department }}</td></tr>
                        <tr><td style="border:1px solid whitesmoke !important;" colspan="2"><b>Programme :</b> {{ rec.prog }}</td></tr>
                        <tr><td style="border:1px solid whitesmoke !important;" colspan="2"><b>Programme Type :</b> {{ rec.programtype }}</td></tr>
                        <tr><td style="border:1px solid whitesmoke !important;" colspan="2"><b>Programme Year :</b> {{ rec.year }}</td></tr>
                        <tr><td style="border:1px solid whitesmoke !important;"><b>Email :</b> {{rec.email}}</td><td style="border:1px solid whitesmoke !important;"><b>Mobile :</b> {{rec.mobileno}}</td></tr>
                        <tr><td style="border:1px solid whitesmoke !important;"><b>Academic Year :</b> {{rec.ay}}</td><td style="border:1px solid whitesmoke !important;"><b>Admission Year :</b> {{rec.ay}}</td></tr>
                    </table>
                    <table style="width: 100%;border:1px solid whitesmoke;">
                        <tr>
                          <th style="border:1px solid black !important;" align="left">
                            <center>Sr.No</center>
                          </th>
                          <th style="border:1px solid black !important;">Particulars</th>
                          <th style="border:1px solid black !important;" align="left">Amount (Rs.)</th>
                        </tr>
                        <tr v-for="item in component_list" :key="item.component">
                          <td style="border:1px solid black !important;" align="center">{{ item.sr_no }}</td>
                          <td style="border:1px solid black !important;" align="left">{{ item.component }}</td>
                          <td style="border:1px solid black !important;" align="right">{{ item.amount }}/-</td>
                        </tr>
                        <tr>
                          <td style="border:1px solid black !important;"></td>
                          <td style="border:1px solid black !important;" align="right"><b>Total Amount </b></td>
                          <td style="border:1px solid black !important;" align="right"><b>{{ component_total_amount }}/-</b></td>
                        </tr>
                    </table>
                    <div>
                      <br />
                      <hr style="height:1px; background:black;border-radius: 50px;"/>
                      <br />
                    </div>
                    <br />
                    <center style="width: 80%;margin-left: 70px; word-wrap: break-word;">DETAILS OF PAYMENT MODE TOWARDS FEES DEPOSITED</center>
                    <br />
                    <table style="width: 100%">
                      <tr>
                        <th style="border:1px solid black !important;">Payment Mode</th>
                        <th style="border:1px solid black !important;">Reference No.</th>
                        <th style="border:1px solid black !important;">Pay Date</th>
                        <th style="border:1px solid black !important;">Amount (Rs.)</th>
                        <th style="border:1px solid black !important;">Drawn On Bank</th>
                      </tr>
                      <tr v-for="item in trans_list" :key="item.tid">
                        <td style="border:1px solid black !important;"><center>{{ item.mode }}</center></td>
                        <td style="border:1px solid black !important;"><center>{{ item.tid }}</center></td>
                        <td style="border:1px solid black !important;"><center>{{ item.date }}</center></td>
                        <td style="border:1px solid black !important;"><center>{{ item.amount }}/-</center></td>
                        <td style="border:1px solid black !important;"><center>{{ item.bank }}</center></td>
                      </tr>
                    </table>
                    <br />
                    <div style="width:100%;height:200px;">
                      <div style="float:right;margin-right:20px;">
                        <img style="width:150px;margin-right:50px;" :src="stamp" />
                        <center style="margin-right:40px;"><b>Seal of University</b></center>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <b style="font-size:12px;">Print Date : {{provisionaladmission.printdate}}</b>
                    <br />
                    <div cols="12" sm="12">
                      <br />
                      <span style="font-weight:bold;font-size:12px;">
                        Note : This is Computer generated Receipt, hence signature is not required.
                      </span>
                    </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
          </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
const CryptoJS = require("crypto-js")
import "@/assets/css/styles.css";
export default {
  data: () => ({
    rec_no: "",
    drawer: null,
    component_list: [],
    rec: null,
    stamp : null,
    photo : null,
    sign : null,
    trans_list: [],
    component_total_amount: 0,
    qrcodeurl: "",
    provisionadmissionid : null,
    provisionaladmission : null,
  }),
  components: {},
  mounted() {
    this.rec_no = this.$route.params.rec_no;
    if (!this.rec_no) 
        this.rec_no = this.decrypt(this.$route.query.rec_no);

    this.provisionadmissionid = this.$route.params.provisionadmissionid;
    if (!this.provisionadmissionid) 
        this.provisionadmissionid = this.decrypt(this.$route.query.provisionadmissionid);

    this.viewreceipt();
    this.viewapplicationdetails();
  },
  methods: {
    printReceipt() {
      var content = document.getElementById("printonlyreceipt").innerHTML;
      var mywindow = document.body.innerHTML;
      document.body.innerHTML = content;
      window.print();
      location.reload();
      document.body.innerHTML = mywindow;
    },

    printApplication() {
      var content = document.getElementById("printonlyapplication").innerHTML;
      var mywindow = document.body.innerHTML;
      document.body.innerHTML = content;
      window.print();
      location.reload();
      document.body.innerHTML = mywindow;
    },

    decrypt (src) {
      const passphrase = '987654'
      const bytes = CryptoJS.AES.decrypt(src, passphrase)
      const originalText = bytes.toString(CryptoJS.enc.Utf8)
      return originalText
    },

    viewreceipt(){
      const data = {
        rec_no: this.rec_no,
      };
      axios
        .post("/appLearnerAdmission/admissionreceipt", data)
        .then((res) => {
          if (res.data.status == "SUCCESS") {
            this.rec = res.data.receipt;
            this.stamp = res.data.stamp;
            this.qrcodeurl = "https://api.qrserver.com/v1/create-qr-code/?data=" + this.rec.srn;
            this.trans_list = res.data.trans_list;
            this.component_list = res.data.component_list;
            this.component_total_amount = res.data.component_total_amount;
          } else {
            console.log("ERROR in API call");
          }
        })
        .catch((error) => {
          window.console.log(error);
          this.message = "Something went wrong";
        });
    },

    viewapplicationdetails(){
      const data = {
        provisionadmissionid: this.provisionadmissionid,
      };
      axios
        .post("/AppLearnerAdmission/getvuapplicationprint", data)
        .then((res) => {
          if (res.data.status == "200") {
            this.provisionaladmission = res.data.provisionaladmission;
            this.photo = res.data.photo;
            this.sign = res.data.sign;
          } else {
            console.log("ERROR in API call");
          }
        })
        .catch((error) => {
          window.console.log(error);
          this.message = "Something went wrong";
        });
    }
  },
};
</script>
<style scoped>
.text-left {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin: 0px !important;
}
.text-left-head {
  font-size: 14px;
  font-weight: bold;
}

input,
textarea {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
h6 {
  font-size: 1em;
  font-family: "Times New Roman", Times, serif ";";
}
input[type="text"] {
  padding: 4px;

  border: none;
}
table {
  border-collapse: collapse;

  font-size: 12px;
  width: 80%;
}

table,
th,
td {
  border: 1px solid black;
}
th {
  height: 30px !important;
  text-align: center !important;
}
th,
td {
  padding:2px;
}
.font-text {
  font-family: "Times New Roman";
}
#printonlyreceipt {
  display: block;
}
#printonlyapplication{
    display: block;
}
#noprint {
  display: block;
}
.pageHeight {
  height: auto;
}

.photo{
    border: 1px solid black !important;
    height: 130px;
    width: 120px;
    margin: 10px;
    padding: 20px;
    text-align: center;
}
@media print {
  #printonlyreceipt {
    display: block;
    font-size: 10px;
  }
  #printonlyapplication{
    display: block;
    font-size: 10px;
  }
  @page {
    size: letter;
  }
  #noprint {
    display: none;
  }
  .pageHeight {
    height: 230mm;
  }

  @page {
    margin-left: 2.1cm;
    margin-right: 1cm;
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
    counter-increment: page;
    content: counter(page);
  }
  body {
    font-size: 15px !important;
  }

  a[href] {
    display: none !important;
  }
  button {
    display: none !important;
  }
  input[type="submit"],
  input[type="button"] {
    display: none !important;
  }
  hr {
    border: none !important;
  }
  input[type="text"] {
    border: none !important;
  }
  input,
  textarea {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}
</style>
